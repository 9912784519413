<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Report Sales - Invoice</h2>
            </CCardHeader>
            <CCardBody>
                <CRow>
                    <!--Col Quantity Per Status-->
                    <CCol class="sm-3">
                        <CRow>
                            <CCol class="sm-1">
                                <label class="form-label font-weight-bold">Quantity Per Status</label>
                            </CCol>
                        </CRow>
                        <div style="border:1px solid lightgrey;padding:10px">
                            <CRow>
                                <CCol class="sm-1">
                                    <CRow>
                                        <CCol class="sm-4">
                                            <label class="form-label font-weight">New</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-3">
                                            <label class="form-label font-weight">{{NewQty}}</label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">Sent</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">{{SentQty}}</label>
                                        </CCol>
                                    </CRow>
                                    <!-- <CRow>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">Delete</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">{{DeleteQty}}</label>
                                        </CCol>
                                    </CRow> -->
                                </CCol>
                            </CRow>
                        </div>

                        <CRow class="pt-3">
                            <CCol class="sm-2" >
                                <label class="form-label font-weight-bold">Tipe Tanggal</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <CSelect id="DateType" class="font-weight-bold" @change="onChangeDateType()" :options="DateTypeData" :value.sync="DateType" />
                                <label id="errorDateType" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>

                        <CRow class="pb-3">
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Tipe Detail</label>
                                <label class="form-label font-weight-bold" style="float:right;">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <v-select id="TypeDetail" :options="TypeDetailData" v-model="TypeDetail" @input="getQuantity()" />
                                <label id="errorTypeDetail" class="form-error" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>

                        <CRow class="pb-3">
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Item</label>
                                <label class="form-label font-weight-bold" style="float:right;">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <v-select id="Item" :options="ItemData" v-model="Item" @input="getQuantity()" />
                                <label id="errorItem" class="form-error" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>

                        <CRow class="pb-3">
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Tipe Project</label>
                                <label class="form-label font-weight-bold" style="float:right;">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <v-select id="ProjectType" :options="ProjectTypeData" v-model="ProjectType" @input="getQuantity()" />
                                <label id="errorProjectType" class="form-error" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                    </CCol>
                    
                    <!--Col Total Invoice Per Status-->
                    <CCol class="sm-3">
                        <CRow>
                            <CCol class="sm-1">
                                <label class="form-label font-weight-bold">Total Invoice Per Status</label>
                            </CCol>
                        </CRow>
                        <div style="border:1px solid lightgrey;padding:10px">
                            <CRow>
                                <CCol class="sm-1">
                                    <CRow>
                                        <CCol class="sm-4">
                                            <label class="form-label font-weight">New</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-3">
                                            <label class="form-label font-weight">{{NewTotalInv}}</label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">Sent</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">{{SentTotalInv}}</label>
                                        </CCol>
                                    </CRow>
                                    <!-- <CRow>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">Delete</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">{{DeleteTotalInv}}</label>
                                        </CCol>
                                    </CRow> -->
                                </CCol>
                            </CRow>
                        </div>

                        <CRow class="pt-3">
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Tanggal Mulai</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3">
                                <date-picker
                                    v-model="DateFrom"
                                    format="DD MMM YYYY"
                                    type="date"
                                    placeholder="Pilih tanggal"
                                    value-type="YYYY-MM-DD"
                                    style="width:100%"
                                    @change = "onChangeDateFrom"
                                ></date-picker>
                                <label id="errorDateFrom" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>

                        <CRow class="pt-3 pb-3">
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Status</label>
                                <label class="form-label font-weight-bold" style="float:right;">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <v-select id="Status" :options="StatusData" v-model="Status" @input="getQuantity()" />
                                <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                        
                        <CRow class="pb-3">
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Customer</label>
                                <label class="form-label font-weight-bold" style="float:right;">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <v-select id="Customer" :options="CustomerData" v-model="Customer" @input="getQuantity()" />
                                <label id="errorCustomer" class="form-error" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                    </CCol>
                    
                    <!--Col Total Invoice Payment Per Status-->
                    <CCol class="sm-3">
                        <CRow>
                            <CCol class="sm-1">
                                <label class="form-label font-weight-bold">Total Invoice Payment Per Status</label>
                            </CCol>
                        </CRow>
                        <div style="border:1px solid lightgrey;padding:10px">
                            <CRow>
                                <CCol class="sm-1">
                                    <CRow>
                                        <CCol class="sm-4">
                                            <label class="form-label font-weight">New</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-3">
                                            <label class="form-label font-weight">{{NewTotalInvPayment}}</label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">Sent</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">{{SentTotalInvPayment}}</label>
                                        </CCol>
                                    </CRow>
                                    <!-- <CRow>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">Delete</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">{{DeleteTotalInvPayment}}</label>
                                        </CCol>
                                    </CRow> -->
                                </CCol>
                            </CRow>
                        </div>

                        <CRow class="pt-3">
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Tanggal Akhir</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3">
                                <date-picker
                                    v-model="DateTo"
                                    format="DD MMM YYYY"
                                    type="date"
                                    placeholder="Pilih tanggal"
                                    value-type="YYYY-MM-DD"
                                    style="width:100%"
                                    @change = "onChangeDateTo"
                                ></date-picker>
                                <label id="errorDateTo" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>

                        <CRow class="pt-3 pb-3">
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Area</label>
                                <label class="form-label font-weight-bold" style="float:right;">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <v-select id="Area" :options="AreaData" v-model="Area" @input="getQuantity()" />
                                <label id="errorArea" class="form-error" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>

                        <CRow class="pb-3">
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Sales</label>
                                <label class="form-label font-weight-bold" style="float:right;">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <v-select id="Sales" :options="SalesData" v-model="Sales" @input="getQuantity()" />
                                <label id="errorSales" class="form-error" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                    </CCol>
                </CRow>

                <hr>
                <CRow>
                    <CCol class="sm-3">
                        <button id="view" class="btn btn-primary" style="float:left"
                            @click="exportExcel()">
                            <i class="fa fa-file-excel-o"></i> Excel
                        </button>
                        &nbsp;
                        <button id="view" class="btn btn-secondary"
                            @click="generatePDF()">
                            <i class="fa fa-file-pdf-o"></i> PDF
                        </button>
                    </CCol>
                </CRow>

                <hr>
                <CRow>
                    <CCol>
                        <sales-report-grid 
                            ref="SalesReportGrid" 
                            :key="gridReload" 
                            :dateTo="DateToChangest" 
                            :dateFrom="DateFromChangest" 
                            :typeDetail="TypeDetail"
                            :itemId="Item"
                            :status="Status"
                            :area="Area"
                            :customer="Customer"
                            :sales="Sales"
                            :projectType="ProjectType"
                        />
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import 'vue2-datepicker/index.css'
import { report, sweet_alert} from '../../../infrastructure/constant/variable';
import moment from 'moment';
import datePicker from 'vue2-datepicker';
import salesReportService from '../SalesReport/Script/SalesReportService.js';
import salesReportGrid from '../SalesReport/Grid/SalesReportGrid.vue';

export default {
    name: 'SalesReport',
    components: {
        'sales-report-grid': salesReportGrid,
        'date-picker': datePicker
    },
    async mounted () {
        this.DateTypeData = this.$globalfunc.getDateTypeData();

        this.onChangeDateType();
        
        this.TypeDetailData = await salesReportService.getTypeDetailQuery();

        var statusArray = await this.$globalfunc.globalDropdown('ddl_status_invoice');
        var index = statusArray.indexOf({value: 'Delete', label: 'Delete'});
        statusArray.splice(index, 1);

        this.StatusData = statusArray;
        this.AreaData = await salesReportService.getAreaQuery();
        this.CustomerData = await salesReportService.getCustomerQuery();
        this.SalesData = await salesReportService.getSalesQuery();
        this.ProjectTypeData = [ 
            {value: 'Project', label: 'Project'},
            {value: 'Retail', label: 'Retail'},
        ];

        this.getQuantity();
    },
    data(){
        return {
            gridReload: 0,
            DateFrom: report.default_start_date,
            DateTo: report.default_end_date,
            DateFromChangest: report.default_start_date,
            DateToChangest: report.default_end_date,

            NewQty: '',
            SentQty:'',
            DeleteQty: '',

            NewTotalInv: '',
            SentTotalInv:'',
            DeleteTotalInv: '',
            
            NewTotalInvPayment: '',
            SentTotalInvPayment:'',
            DeleteTotalInvPayment: '',

            Error: 0,

            DateTypeData: [],
            DateType: report.default_date,

            TypeDetailData: [],
            TypeDetail: null,

            ItemData: [],
            Item: null,

            StatusData: [],
            Status: null,

            AreaData: [],
            Area: null,

            CustomerData: [],
            Customer: null,

            SalesData: [],
            Sales: null,

            ProjectTypeData: [],
            ProjectType: null,
        }
    },
    methods: {
        async getQuantity(){
            this.getItem();
            var variables = {
                startDate: this.DateFrom,
                endDate: this.DateTo,
                typeDetail: this.TypeDetail == null ? null : this.TypeDetail.value,
                itemId: this.Item == null ? null : this.Item.value,
                status: this.Status == null ? null : this.Status.value,
                areaCode: this.Area == null ? null : this.Area.value,
                customer: this.Customer == null ? null : this.Customer.value,
                sales: this.Sales == null ? null : this.Sales.value,
                projectType: this.ProjectType == null ? null : this.ProjectType.value
            };
            
            var dataSalesReportSummary = await salesReportService.getSalesReport(variables);

            var summaryQuantity = dataSalesReportSummary.summary.quantity_per_status;
            var summaryTotalInvoice = dataSalesReportSummary.summary.total_invoice_per_status;
            var summaryTotalInvoicePayment = dataSalesReportSummary.summary.total_invoice_payment_per_status;

            this.NewQty = summaryQuantity.New == undefined ? 0 : summaryQuantity.New;
            this.SentQty = summaryQuantity.Sent == undefined ? 0 : summaryQuantity.Sent;
            this.DeleteQty = summaryQuantity.Delete == undefined ? 0 : summaryQuantity.Delete;
            
            this.NewTotalInv = summaryTotalInvoice.New == undefined ? 0 : kendo.format('{0:N0}', summaryTotalInvoice.New);
            this.SentTotalInv = summaryTotalInvoice.Sent == undefined ? 0 : kendo.format('{0:N0}', summaryTotalInvoice.Sent);
            this.DeleteTotalInv = summaryTotalInvoice.Delete == undefined ? 0 : kendo.format('{0:N0}', summaryTotalInvoice.Delete);
            
            this.NewTotalInvPayment = summaryTotalInvoicePayment.New == undefined ? 0 : kendo.format('{0:N0}', summaryTotalInvoicePayment.New);
            this.SentTotalInvPayment = summaryTotalInvoicePayment.Sent == undefined ? 0 : kendo.format('{0:N0}', summaryTotalInvoicePayment.Sent);
            this.DeleteTotalInvPayment = summaryTotalInvoicePayment.Delete == undefined ? 0 : kendo.format('{0:N0}', summaryTotalInvoicePayment.Delete);

            this.gridReload++;
        },
        async getItem(){
            if (this.TypeDetail != null) {
                var variables = {
                    typeDetailId: this.TypeDetail.value,
                };

                this.ItemData = await salesReportService.getItemQuery(variables)
            }
        },
        async onChangeDateType(){
            var dateTypeData = this.$globalfunc.getDateTypeDetail(this.DateType);

            this.DateFrom = dateTypeData.startDate == null ? this.DateFrom : dateTypeData.startDate;
            this.DateTo = dateTypeData.endDate == null ? this.DateTo : dateTypeData.endDate;
            this.DateFromChangest = this.DateFrom;
            this.DateToChangest = this.DateTo;

            await this.getQuantity();
        },
        async onChangeDateFrom(){
            var vue = this;
            if((moment(this.DateFrom).valueOf()-moment(this.DateTo).valueOf()) > 0){
                this.DateFrom = this.DateFromChangest;
                vue.$swal("Error", "Tanggal awal tidak boleh lebih besar dari tanggal akhir", "error");
            }else{
                this.DateFromChangest = this.DateFrom;
                this.DateToChangest = this.DateTo;
                await this.getQuantity();
                this.DateType = 'Custom';
            }
        },
        async onChangeDateTo(){
            var vue = this;
            if((moment(this.DateFrom).valueOf()-moment(this.DateTo).valueOf()) > 0){
                this.DateTo = this.DateToChangest;
                vue.$swal("Error", "Tanggal awal tidak boleh lebih besar dari tanggal akhir", "error");
            }else{
                this.DateFromChangest = this.DateFrom;
                this.DateToChangest = this.DateTo;
                await this.getQuantity()
                this.DateType = 'Custom';
            }
        },
        async exportExcel() {
            this.$refs.SalesReportGrid.exportExcel();
        },
        generatePDF(){
            // this.$swal(sweet_alert.print).then((result) => {
            //     if (result.isConfirmed == true) {
            //         this.$refs.SalesReportGrid.generatePDF();
            //     }
            //     else if (result.isDenied) {
            const routeData = this.$router.resolve({name: 'Sales Report Html Template', params: { startdate: this.DateFrom, enddate: this.DateTo }});
            window.open(routeData.href, '_blank');
            //     }
            // });
        }
    }
}
</script>

<style scoped>
.sm-2, .sm-3, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
@media (min-width: 768px) {
    .sm-2, .sm-3, .col-sm-half {
        float: left;
    }
    .sm-2 {
        width: 12%;
    }
    .sm-3 {
        width: 21%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}

</style>